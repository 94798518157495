'use client';
import { getUrlParam } from '@/utils/getUrlParams';
import Script from 'next/script';
import { useEffect, useRef } from 'react';
import '@/styles/calendly.css';
import posthog from 'posthog-js';

interface CalendlyProps {
  url: string;
}

interface UtmParams {
  utm_campaign?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_content?: string;
  utm_term?: string;
}

function getUtmParams(): UtmParams {
  return {
    utm_campaign: getUrlParam('utm_campaign', ''),
    utm_source: getUrlParam('utm_source', ''),
    utm_medium: getUrlParam('utm_medium', ''),
    utm_content: getUrlParam('utm_content', ''),
    utm_term: getUrlParam('utm_term', ''),
  };
}

function getUtmQueryParams(utmParams: UtmParams): string {
  const queryParams = Object.entries(utmParams)
    .filter(([_, value]) => value !== undefined && value !== '')
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

  return queryParams ? `?${queryParams}` : '';
}

export function CalendlyEmbed({ url }: CalendlyProps) {
  const utmParams = useRef<UtmParams>({});
  const queryParams = useRef<string>('');

  useEffect(() => {
    utmParams.current = getUtmParams();
    queryParams.current = getUtmQueryParams(utmParams.current);
  });

  return (
    <>
      {/* Calendly inline widget begin */}
      <div
        className="calendly-inline-widget flex w-full"
        data-url={url + queryParams.current}
        style={{ minWidth: 320, height: 700 }}
      />
      {/* Calendly inline widget end */}
      <Script
        type="text/javascript"
        src="https://assets.calendly.com/assets/external/widget.js"
        async
        strategy="afterInteractive"
      />
    </>
  );
}
