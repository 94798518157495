function getUrlVars() {
  var vars: any = {};
  var parts = window.location.href.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    function (m, key, value) {
      vars[key] = value;
      return value;
    },
  );
  return vars;
}

export function getUrlParam(parameter: string, defaultvalue: string) {
  var urlparameter = defaultvalue;
  if (window.location.href.indexOf(parameter) > -1) {
    urlparameter = getUrlVars()[parameter];
  }
  if (urlparameter !== undefined) {
    return decodeURI(urlparameter);
  } else {
    return defaultvalue;
  }
}
